/*------------------------------------*\
  styles
\*------------------------------------*/



.skills {
  background: white;



  // hero and section are almost the same

  .skills-hero {
    display: block;
    height: 60vh;
    @media (max-width: 1400px) {
      height: 80vh;
    }
    @media(max-width: 500px){
      height: 100vh;
    }
    width: auto;
    position: relative;
    //background-color: #25282a;
    background-color: #25282a;


    html.mod-browser-ie &,
    html.mod-browser-edge & {
      z-index: 60;
    }
    
    &::before {
      position: absolute;
      z-index: 10;
      background: rgba(#25282a, 0.9);
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &:after {
      content: '';
      background: url("../img/raw/mobile-hero-arrow.svg");
      @media (max-width: 500px) {
        height: 60px;
        width: 10px;
        display: block;
        z-index: 99;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .skills-hero-bg {
    display: block;
    background-size: cover;
    background-position: 50%;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }


  .skills-hero-front {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }


  .skills-hero-front-content {
    display: block;
    height: auto;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    text-align: left;
    margin: 0 auto;

  }


  .skills-hero-toptitle {
    font-size: 14px;
    font-weight: 600;
    color: rgba(#c0bb87,1);
    line-height: 1.2;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    @media(max-width: 500px){
      font-size: 12px;
    }
  }

  .skills-hero-title {
    font-size: 60px;
    font-weight: 400;
    color: rgba(white,1);
    line-height: 1.2;
    font-family: $font2;

    @media(max-width: 1400px) {
      font-size: 52px;
    }


    @media(max-width: 1000px){
      font-size: 50px;
    }
    @media(max-width: 800px){
      font-size: 38px;
      line-height: 1;
    }
  }

  .skills-hero-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: rgba(white,1);
    line-height: 1.2;
    margin-top: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }


  .skills-mobile-title {
    @media (min-width: 500px) {
      display: none;
    }
    font-size: 50px;
    padding: 0 0 20px 0;
    line-height: 1;
    font-family: $font2;
    text-align: center;
    color: #c0bb87;
    margin-bottom: 20px;
  }



  .skills-verticals {
    display: flex;
    justify-content: center;
    background-image: url("../img/raw/dash-storytelling.svg");
    @media (max-width: 1000px) {
     flex-wrap: wrap;
    }
    @media (max-width: 500px) {
      background-image: none;
      background-color: white;
      padding-top: 60px;
      display: block;
    }
    background-size: 90%;
    background-position: center;
    background-color: #25282a;

    &.mod-first{
      margin: 0 5%;
      margin-top: 45px;
      @media (max-width: 1000px) {
        margin: 0;
      }

    }


    &.mod-second {
      margin: 0 20%;
      background-image: url("../img/raw/dash-building.svg");
      @media (max-width: 500px) {
        margin: 0;
        padding-top: 0;
      }
    }
  }

  .skills-images {
    padding-top: 200px;
    padding-bottom: 200px;
    margin: 0 5%;
    background: rgba(white, 0.9);
    z-index: 222;
    position: relative;

    @media (max-width: 500px) {
      margin: 0;
      padding-top: 0;
      padding-bottom: 60px;
    }

    .skills-images-wrapper {
      position: relative;

      img {
        &:first-of-type {
          width: 80%;
          position: relative;
          @media (max-width: 500px) {
            width: 100%;
          }
        }

        &:last-of-type {
          max-width: 40%;
          left: 5%;
          bottom: 0;
          position: absolute;
          @media (max-width: 500px) {
            display: none;
          }
        }
      }
    }


    &.mod-first {
      .skills-images-wrapper {
        text-align: right;
      }
    }

    &.mod-second{
      .skills-images-wrapper {
        text-align: left;
      }
      img {
        &:last-of-type {
          right: 25%;
          left:auto;
          @media (max-width: 500px) {
            display: none;
          }
        }
      }
    }
  }


  .skills-abheads {
    padding-top: 40px;
    padding-bottom: 40px;
    background: white;
    //min-height: 100vh;
    //padding: 20px 20px;
    position: relative;
    @media(max-width: 500px){
      display: none;
    }

    &.mod-second {
      padding-top : 0;
      padding-bottom: 0;
      .skills-abheads-title {
      top: -1100px;
        left: 0;
        font-weight: 500;

      }
      .skills-abheads-title-text {
        font-family: $font2;
        text-align: center;
        font-weight: 500;
        font-size: 260px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        @media(max-width: 1400px){
          font-size: 210px;
          font-weight: 200;
        }

        @media(max-width: 1000px){
          font-size: 120px;
        }

      }
    }
  }


  .skills-abheads-title {
    //top: -135px;
    //left: 10%;
    right: 0;
    z-index: 50;
    mix-blend-mode: multiply;

    display: block;
    position: absolute;
    top: -33vh;
    width: 1000px;
    margin: auto;
    left: 0;

    html.mod-browser-ie &,
    html.mod-browser-edge & {
      color: rgba(#1a1a10,1);
      position: relative;
      z-index: 40;
    }
  }

  .skills-abheads-title-text {
    display: inline-block;
    font-size: 300px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    text-align: left;
    user-select: none;
    color: rgba(#c0bb87,1);
            @media(max-width: 1000px){
          font-size: 180px;
        }
  }

  .skills-svg {
    padding: 0 5%;
    background: rgba(255,255,255,0.9);
    position: relative;
    z-index: 99;
    svg {
      display: block;
      margin: auto;
      max-width: 540px;
      @media (max-width: 700px) {
        max-width: 100%;
      }
      height: auto;
    }

    .hover-wheel{
      max-width: 500px;
      margin: auto;
      position: relative;
      padding: 60px 0 120px 0;
      @media (max-width: 500px) {
        padding: 40px 0 160px 0;
      }

      .js-hover {
        cursor: pointer;
      }

      .hover-wheel__hover {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        text-align: center;
        width: 100%;
        @media (max-width: 500px) {
          width: 100%;
          text-align: center;
        }
        &.is-active {
          opacity: 1;
          pointer-events: all;
        }


        .hover-wheel__subheading {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
          font-family: $font1;
          letter-spacing: 2px;
          text-transform: uppercase;
          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
        .hover-wheel__wysiwyg {
          &, p {
            letter-spacing: 0.025em;
            font-size: 16px;
            font-family: $font1;
          }
        }
      }
    }
    .skills-cta {
    text-align: center;
      padding: 60px 0 120px 0;
      @media (max-width: 800px) {
        padding: 60px 0;
      }
      .skills-cta-button {
        font-size: 16px;
        display: inline-block;
        border: 2px solid;
        padding: 20px 40px;
        font-weight: 600;

      }
    }
  }
}
