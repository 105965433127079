/*------------------------------------*\
  styles
\*------------------------------------*/


  .about {

  }


  .about-sep-wrapper {
    position: relative;
  }

  .about-sep {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-0%, -50%);
    width: 100px;
    background: rgba(rgb(193, 187, 134),.9);
    height: 2px;

    @media (max-width: 700px) {
      display: none;
    }
  }





  .about-hero {

    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;


    @media(max-width: 1200px) {
      //height: 600px;
    }

    @media(max-width: 1100px) {
      height: 100vh;
    }

  }

    .about-hero-inner {
      max-width: 1200px;
      padding: 0px 100px;
      margin: 0 auto;


      @media(max-width: 1500px) {
      }

      @media(max-width: 1200px) {
        padding: 0 50px;
      }

      @media(max-width: 800px) {
        padding: 0 20px;
      }


    }

    .about-hero-toptitle {
      font-size: 14px;
      font-weight: 600;
      color: rgba(#c0bb87,1);
      line-height: 1.2;
      margin-bottom: 30px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .about-hero-title {
      font-size: 70px;
      font-weight: 400;
      color: rgba(white,1);
      line-height: 1.2;
      font-family: $font2;

      @media(max-width: 1400px){
        font-size: 60px;
      }
      @media(max-width: 1200px){
        font-size: 50px;
      }
      @media(max-width: 1000px){
        font-size: 40px;
      }
      @media(max-width: 500px){
        //font-size: 30px;
      }


    }





    .about-abpoints {
      padding-bottom: 100px;
      @media (max-width: 700px) {
        padding: 0 20px;
      }
    }







    .about-abheads {
      padding-top: 300px;
      padding-bottom: 300px;
      background: white;
      //min-height: 100vh;
      //padding: 20px 20px;
      position: relative;

      @media (max-width: 700px) {
        padding: 0;
        padding-top: 100px;
      }
    }


      .about-abheads-title {
        text-align: center;
        display: block;
        position: absolute;
        top: 240px;
        left: 0;
        right: 0;
        z-index: 50;
        mix-blend-mode: multiply;
        @media (max-width: 700px) {
          position: relative;
          top: 0;
          padding-bottom: 40px;
        }


      }

        .about-abheads-title-text {
          display: inline-block;
          font-size: 270px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.85;
          text-align: left;
          user-select: none;
          color: rgba(#c0bb87,1);
          @media (max-width: 700px) {
            font-size: 50px;
            display: block;
            margin: 0 30px;
          }
        }















  .about-logos {
    padding: 200px 0 300px 0;
    @media (max-width: 700px) {
      padding: 100px 20px ;
      padding-top: 0;
    }
  }






      .about-logogrid {
        display: block;
        position: relative;
        padding-top: 100px;
      }


        .about-logogrid-bg-text-wrapper {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 50;
          pointer-events: none;
          user-select: none;
          mix-blend-mode: overlay;
          text-align: left;
          @media (max-width: 700px) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            text-align: center;
          }
        }

        .about-logogrid-bg-text {
          display: block;

          span {
            font-size: 300px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: .85;
            opacity: 1;
            color: #1d1c15;
            @media (max-width: 700px) {
              font-size: 50px;
              color: #c0bb87;
            }
          }

        }


        .about-logogrid-inner {
          display: block;
          width: 100%;
          max-width: 1200px;
          padding: 0 100px;
          text-align: center;
          margin: 0 auto;

          html.mod-browser-ie &,
          html.mod-browser-edge & {
            position: relative;
            z-index: 60;
          }

          @media (max-width: 700px) {
            padding: 0;
          }
        }


          .about-logogrid-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

          }


          .about-logogrid-item {
            display: inline-block;
            width: 33.33%;
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 800px) {
              min-height: 150px;

            }
            @media (max-width: 500px) {
             width: 50%
            }

            img {
              width: 100%;
              height: auto;
              max-width: 200px;
            }

          }




          @media (max-width: 700px) {
            .hide-for-mobile {
              display: none;
            }
          }




//.skrollr-mobile {
  [disable-for-mobile] {
    @media (max-width: 700px) {
      transform: none!important;
      opacity: 1!important;
    }
  }
//}
