/*------------------------------------*\
  quote
\*------------------------------------*/

.quote {
  font-size: 16px;
}






/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 1442px) {}


/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 1282px) {}


/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 1026px) {}


/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 902px) {}


/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 770px) {}


/*------------------------------------*\
  breakpoint
\*------------------------------------*/
@media screen and (max-width: 447px) {}
