/*------------------------------------*\
  styles
\*------------------------------------*/


  .aboutnav {
    position: fixed;
    background: rgba(#25282a, .9);
    border-bottom: 1px solid rgba(white,.08);
    z-index: 4;
    left: 0;
    right: 0;
    top: 0;
    display: block;

    will-change: transform;
    backface-visibility: hidden;

    transform: translateY(-100%);
    transition: transform 500ms cubic-bezier(0.77, 0, 0.175, 1), background 300ms ease-in-out, border 300ms ease-in-out;

    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 370px){
      display: none;
    }

    body.page-template-page-about & {
    }

    body.mod-page-barba-active & {
      pointer-events: none;
      transform: translateY(-100%);
    }


    html.mod-top-near-light-section & {
      background: rgba(white,.9);
      border-color: rgba(#25282a,.1);
    }


    &.mod-visible {
      transform: translateY(0%);
    }


  }

    .aboutnav-inner {
      text-align: center;
    }

      .aboutnav-links {
        display: flex;
        margin: auto;
        text-align: center;
        justify-content: center;


        @media (max-width: 1200px) {
          padding-left: 35px;
        }

        @media (max-width: 800px) {
          //display: block;
          text-align: left;
          padding-left: 10px;
          justify-content: space-between;
        }

        @media (max-width: 700px) {
          padding-left: 15px;
          padding-right: 15px;
        }

      }

      .aboutnav-link {
        display: inline-block;
        padding: 48px 0 35px 0;
        margin: 0 25px;
        position: relative;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 0.7px;
        outline: none;

        color: rgba(white,.7);

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: #c0bb87;
          height: 4px;
          width: 100%;
          content: '';
          opacity: 0;
          transition: opacity 300ms ease-in-out;
        }

        &.mod-past {
          //color: rgba(37,40,42,.7);
          &::before {
            opacity: 0;
          }
        }

        &.mod-active {
          color: rgba(#c0bb87,1);
          //font-weight: 400;
          &::before {
            opacity: 1;
          }
        }





        html.mod-top-near-light-section & {

          color: rgba(#25282a,.7);

          &.mod-past {
            color: rgba(#25282a,.6);
            &::before {
              opacity: 0;
            }
          }

          &.mod-active {
            color: rgba(#c0bb87,1);
            //font-weight: 400;
            &::before {
              opacity: 1;
            }
          }
        }







        @media (max-width: 1500px) {
          font-size: 14px;
        }




        @media (max-width: 1200px) {
          font-size: 14px;
          padding: 50px 0 30px 0;
          margin: 0 12px;
        }

        @media (max-width: 1000px) {
          font-size: 12px;
          padding: 52px 0 28px 0;
          margin: 0 10px;
          &::before {
            height: 2px;
          }
        }

        @media (max-width: 900px) {
          font-size: 12px;
          padding: 53px 0 25px 0;
          margin: 0 10px;
        }

        @media (max-width: 800px) {
          font-size: 12px;
          padding: 60px 0 20px 0;
          margin: 0 10px;
        }

        @media (max-width: 700px) {
          font-size: 9px;
          padding: 60px 0 10px 0;
          margin: 0;
          margin-left: 5px;

        }

      }
