.btn {

  font-size: 16px;

  &.btn--arrow {
    padding: 20px 0;
    display: inline-block;
    @media (max-width: 1000px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-size: 24px;
      font-weight: 400;
      color: rgba(#25282a,1);
      line-height: 1.3;
      display: inline-block;
      font-family: $font2;
      transition: all 300ms ease-out;

    }

    svg {

      transition: all 300ms ease-out;



      height: 22px;
      padding-top: 7px;
      width: auto!important;

      display: inline-block!important;
      margin-left: 30px!important;
      @media(min-width: 1000px) {
      opacity: 0;
      transform: translateX(-100%);
      }

      g {
        stroke: rgba(#c0bb87,1);
        @media(min-width: 1000px) {
          stroke: rgba(white,.9);
        }
      }


      path {
        //fill: white;
      }
    }


    &:hover svg,
    &:focus svg {
      opacity: 1;
      transform: translateX(0%);
      g {
        stroke: rgba(#c0bb87,1);
      }

    }

    &:hover span,
    &:focus span {
      color: rgba(#c0bb87,1);
    }
  }

  &.mod--left-90 {
    padding-left: 92px;
    @media (max-width: 1000px) {
      padding-left: 0;
    }
  }

  &.mod--top-25 {
    margin-top: 25px;
  }
}
