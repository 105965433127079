/*------------------------------------*\
  styles
\*------------------------------------*/

$easing-expo: cubic-bezier(1, 0, 0, 1);
$easing-quart: cubic-bezier(0.77, 0, 0.175, 1);


  .galaxy {

    min-height: 100vh;
    body.mod-sidemenu-open &,
    body.mod-menu-open & {
      overflow-y: scroll;
    }



  }




    .galaxy-inner {
      transition: transform 800ms $easing-quart, filter 500ms $easing-quart, transform-origin 800ms $easing-quart;
      transform-origin: center center;

      backface-visibility: hidden;
      transform-style: preserve-3d;


      body.mod-menu-open & {
        //transform: scale(0.97) translateX(-0%);
        transform: translateY(200px);
        filter: grayscale(100%);
      }


      body.mod-sidemenu-open & {
        //transform: scale(0.97) translateX(-5%);
        //transform: scale(0.97) translateX(-5%);
        transform: translateX(-200px);
        filter: grayscale(100%);
      }


      body.mod-page-transition & {
        //transform: translateY(50px);
        //transform: scale(0.97);
        //transform: translateY(10%);
        filter: grayscale(100%);
      }


      @media(max-width: 1200px) {

        body.mod-sidemenu-open &,
        body.mod-menu-open & {
          transform: none;
          filter: grayscale(100%);
        }

        body.mod-page-transition & {
          transform: none;
          filter: grayscale(100%);
        }

      }

    }



    .galaxy-shade {
      transition:
        bottom 400ms ease-in-out,
        top 400ms ease-in-out,
        transform 400ms $easing-quart,
        opacity 400ms ease-in-out;

      backface-visibility: hidden;
      transform-style: preserve-3d;

      transform-origin: 50% 50%;
      backface-visibility: hidden;
      //transform: scale3d(0,0,0);
      background: rgba(#25282a,1);
      position: fixed;
      z-index: 400;
      //bottom: 100%;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: 0 0 400px 400px rgba(#25282a,.3);
      opacity: .0;
      pointer-events: none;

      body.mod-sidemenu-open &,
      body.mod-menu-open & {
        //bottom: 0%;
        opacity: .5;
        pointer-events: auto;
      }

      body.mod-page-transition & {
        opacity: 1;
        pointer-events: auto;
      }

    }
