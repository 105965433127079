/*------------------------------------*\
  styles
\*------------------------------------*/

$easing-expo: cubic-bezier(1, 0, 0, 1);
$easing-quart: cubic-bezier(0.77, 0, 0.175, 1);

.menu {
  text-align: center;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 500;
  background: white;

  backface-visibility: hidden;
  transform-style: preserve-3d;

  transition: transform 800ms $easing-quart, opacity 800ms $easing-quart;
  transform: translateY(-100%);
  //opacity: .5;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  opacity: 0;

  body.mod-menu-open & {
    transform: translateY(0%);
    opacity: 1;
  }



  @media(max-width: 800px) {
    bottom: 0;
    text-align: left;
  }

}

  .menu-inner {
    opacity: 0;
    transition: opacity 600ms $easing-quart;
    padding: 120px 0 30px 0;

    body.mod-menu-open & {
      opacity: 1;
    }

    @media(max-width: 800px) {
      margin-top: 30px;
      padding: 0px 20px 40px 20px;
      width: 100%;
      height: 80%;
      height: calc(100% - 80px);

      overflow: auto;
    }

  }


    .menu-navs {
      //padding: 100px 10px;
      white-space: nowrap;
    }


      .menu-nav {
        font-size: 30px;
        line-height: 1.2;
        font-weight: 400;
        margin: 0 30px;
        font-family: $font2;
        color: rgba(#25282a,.9);
        outline: none;
        position: relative;

        padding: 10px 0;
        display: inline-block;
        overflow: hidden;
        transition: color 0.5s ease;

        &:before{
          content: '';
          position: absolute;
          transition: .5s ease;
          left: 0;
          width: 100%;
          height: 2px;
          background: rgba(#25282a, .9)
        }
        &:before {
          bottom: 0px;
          transform:  translateX(-100%);
        }
        &:after {
          top: 0px;
          transform:  translateX(100%);
        }
        &.mod-active,
        &:focus::before,
        &:hover::before, &:hover::after {
          //transform: scale(1);
          //opacity: 1;
          background: rgba(#c0bb87, .9);
          transform:  translateX(0);

        }
        &:hover {
          color: #c0bb87;
        }



        @media(max-width: 800px) {
          display: block;
          margin: 15px 0;
          font-size: 28px;

          &::before {
            bottom: -10px;
            height: 2px;
          }
        }


      }


  .menu-text {
    margin-top: 90px;
    margin-bottom: 20px;


    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;

    //letter-spacing: 1.5px;
    font-family: $font2;
    //text-transform: uppercase;

    color: rgba(#25282a,.9);


    @media(max-width: 800px) {
      position: absolute;
      bottom: 0;
      margin-top: 0;
      background: white;
      left: 0;
      right: 0;
      padding: 20px 20px 20px 20px;
    }


  }



  .menu-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    height: 120px;
    z-index: 99;
    pointer-events: none;
    background: transparent;
    transition: 0.3s;

    &.active {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0,0,0,0.75) 30%, rgba(37,40,42,0) 100%);

    }
  }


  html.mod-top-near-light-section .menu-overlay {
    background: none!important;
  }
