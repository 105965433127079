/*------------------------------------*\
  styles
\*------------------------------------*/


.footer {
  text-align: center;
  padding-bottom: 100px;

  &.mod-white {
    background: white;
  }
}


  .footer-inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

  }


    .footer-inner-inner {
      padding-top: 120px;
      .footer.mod-separator & {
        border-top: 1px solid rgba(white,.1);
      }

      .footer.mod-separator.mod-white & { border-top: 1px solid rgba(#25282a,.1);}

    }

    .footer-logo {
      padding-right: 0;
      @media (max-width: 800px) {
      }
    }

      .footer-logo-image {
        display: inline-block;
        vertical-align: middle;
        svg {
          width: 120px;
          height: auto;
        }

        svg path {
          fill: white;
          .footer.mod-white & {fill: #25282a;}
        }


      }


      .footer-logo-text {
        display: inline-block;
        vertical-align: middle;
        font-family: $font2;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;

        padding-left: 20px;
        margin-left: 20px;

        border-left: 1px solid rgba(white,.2);

        @media (max-width: 800px) {
          border-left: 0;
          margin-left: 0;
          //margin-top: 20px;
        }
        .footer.mod-white & {
          border-left: 1px solid rgba(#25282a,.1);

          @media (max-width: 800px) {
            border-left: 0;
          }
        }

        color: rgba(white,.8);
        .footer.mod-white & {color: rgba(#25282a,.8);}


      }



    .footer-social {
      padding: 35px 0;
    }

    .footer-copyright {
      //max-width: 220px;
      display: inline-block;
    }

    .footer-address {
      //display: inline-block;
      //padding-right: 35px;
      padding-bottom: 20px;
    }



      .footer-social-item {
        font-size: 15px;
        font-size: 0;
        display: inline-block;
        vertical-align: middle;
        padding: 10px 10px;
        margin: 0 40px;
        @media (max-width: 1000px) {
          margin: 0 20px;
        }

        color: rgba(white,1);
        .footer.mod-white & {color: rgba(#25282a,1);}

        svg {
          width: 20px;
          height: auto;
        }

        svg path {
          transition: fill 100ms ease-in-out;
          fill: rgba(white,.7);
          .footer.mod-white & {fill: rgba(#25282a,1);}
        }

        &:hover, &:focus {
          svg path {
            fill: rgba(white,.95);
            .footer.mod-white & {fill: rgba(#25282a,.95);}
          }

        }

        &.mod-facebook svg {
          width: 21px;
        }
        &.mod-instagram svg {
          width: 21px;
        }
        &.mod-linkedin svg {
          width: 19px;
        }

      }



    .footer-bottom {
      //font-family: $font2;
      font-size: 10px;
      font-weight: 400;
      line-height: 1.2;

      letter-spacing: 1.5px;
      text-transform: uppercase;
      //display: flex;
      justify-content: center;

      color: rgba(white,.6);
      @media (max-width: 800px) {
        font-size: 10px;
      }
      .footer.mod-white & {color: rgba(#25282a,.6);}

      strong {
        letter-spacing: 1.5px;
        font-weight: 600;
        color: rgba(255,255,255,.6);

        color: rgba(white,.9);
        .footer.mod-white & {color: rgba(#25282a,1);}

      }
    }

    .footer-cta {
      text-align: center;
      padding: 60px 0 120px 0;
      @media (max-width: 800px) {
        padding: 60px 0;
      }

      &.no-padding {
        padding: 0;
      }

      &.no-padding-bottom {
        padding-bottom: 0;
      }
      .skills-cta-button {
        font-size: 16px;
        display: inline-block;
        border: 2px solid;
        padding: 20px 40px;
        font-weight: 600;

      }
    }
