@charset "UTF-8";
/*! Copyright 2015 to eternity: Dash Agency | http://dashagency.com/ */


/*------------------------------------*\
  Base
\*------------------------------------*/

  // variables
  @import "base/_variables.scss";

  // mixins
  @import "base/_mix-utilities.scss"; // utilities mixins

  // styles
  @import "base/_normalize-sanitize.scss"; // github.com/DominikSerafin/normalize-sanitize
  @import "base/_base-styles.scss"; // default elements styles

  // debug
  //@import "blocks/_debug-skrollr.scss";


/*------------------------------------*\
  BEM Blocks
\*------------------------------------*/

@import "blocks/_barba.scss";
@import "blocks/_quote.scss";
@import "blocks/_button.scss";
@import "blocks/_parts.scss";
  @import "blocks/_animations.scss";
  @import "blocks/_footer.scss";
  @import "blocks/_galaxy.scss";
  @import "blocks/_home.scss";
  @import "blocks/_logowrap.scss";
  @import "blocks/_hamburger.scss";
  @import "blocks/_menu.scss";
  @import "blocks/_sidemenu.scss";
  @import "blocks/_about.scss";
  @import "blocks/_ventures.scss";
  @import "blocks/_aboutnav.scss";
  @import "blocks/_abpoint.scss";
  @import "blocks/_abhead.scss";
  @import "blocks/_skills.scss";
  @import "blocks/_skillvert.scss";
  @import "blocks/_case.scss";
  @import "blocks/_contact.scss";
  @import "blocks/_culture.scss";
  @import "blocks/_career.scss";
  @import "blocks/_work.scss";
  @import "blocks/_workpiece.scss";
  @import "blocks/_dashvideo.scss";

//@import "blocks/__hackDisableMobileSkrollr.scss";
