/*------------------------------------*\
  styles

  - culture page with all the careers
\*------------------------------------*/



.culture {
  background: #fff;

  .culture-hero {
    display: block;
    height: 60vh;
    width: auto;
    position: relative;
    //background-color: #25282a;
    background-color: #25282a;
    @media (max-width: 500px) {
      height: 100vh;
    }


    html.mod-browser-ie &,
    html.mod-browser-edge & {
      z-index: 60;
    }

    &::before {
      position: absolute;
      z-index: 10;
      background: rgba(#25282a, 0.9);
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &:after {
      content: '';
      background: url("../img/raw/mobile-hero-arrow.svg");
      @media (max-width: 500px) {
        height: 60px;
        width: 10px;
        display: block;
        z-index: 99;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .culture-hero-bg {
    display: block;
    background-size: cover;
    background-position: 50%;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }


  .culture-hero-front {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }


  .culture-hero-front-content {
    display: block;
    height: auto;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    @media (max-width: 1000px) {
      padding: 0 30px;
    }
    text-align: left;
    margin: 0 auto;

  }


  .culture-hero-toptitle {
    font-size: 14px;
    font-weight: 600;
    color: rgba(#c0bb87,1);
    line-height: 1.2;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    @media(max-width: 500px){
      font-size: 12px;
    }
  }

  .culture-hero-title {
    font-size: 60px;
    font-weight: 400;
    color: rgba(white,1);
    line-height: 0.92;
    font-family: $font2;
    @media(max-width: 1000px){
      font-size: 50px;
    }
    @media(max-width: 500px){
      font-size: 40px;
    }
  }

  .culture-hero-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: rgba(white,1);
    line-height: 1.2;
    margin-top: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }


  .culture-abheads {
    padding-top: 60px;
    padding-bottom: 60px;
    background: white;
    //min-height: 100vh;
    //padding: 20px 20px;
    position: relative;

    @media(max-width: 800px){
      display: none;
    }


    &.mod-second {
      pointer-events: none;
      .culture-abheads-title {
        opacity: 0.1;
      }

      .culture-abheads-title-text {
        font-family: $font2;
        font-weight: 300;

      }
    }
  }


  .culture-abheads-title {
    display: block;
    position: absolute;
    top: -38vh;
    left: 45%;
    @media (max-width: 1000px){
      left: 50%;
    }
    right: 0;
    z-index: 50;
    mix-blend-mode: multiply;
    width: 960px;
    max-width: 100%;



  }

  .culture-abheads-title-text {
    display: inline-block;
    font-size: 270px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.85;
    text-align: left;
    user-select: none;
    color: rgba(#c0bb87,1);
    @media(max-width: 1000px){
      font-size: 180px;
    }


    html.mod-browser-ie &,
    html.mod-browser-edge & {
      color: rgba(#1a1a10,1);
      position: relative;
      z-index: 40;
    }

  }
  .culture-images {
    backgorund: white;
    .culture-images-wrapper {
      position: relative;
      @media (max-width: 800px) {
       margin: 0 -12.5%;
      }

      @media (max-width: 500px) {
        margin: 0;
      }

      img {
        //max-width: 100%;
        width: 100%;
        &:first-of-type {
          position: relative;
        }


      }
    }


    &.mod-first {
      .culture-images-wrapper {
        text-align: right;
      }
    }

  }

  .culture-wrapper {
    max-width: 50%;
    margin: auto;
    @media (max-width: 1300px) {
      max-width: 70%;
    }
    @media (max-width: 1000px) {
     max-width: 80%;
      //margin-top: 60px;

    }
    @media (max-width: 500px) {
      max-width: 100%;
      margin: 0;
      //margin-top: 60px;
    }
  }

  .culture-content {
    max-width: 600px;
    padding-left: 45px;
    @media (max-width: 800px) {
      padding-right: 30px;
      //padding-left: 30px;
      padding-left: 0;
    }
        @media (max-width: 500px) {
     padding-left: 30px;
    }

    .culture-diversity {
      font-weight: 600;
      color: #c0bb87;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 20px;
    }

    // This will probably be a WYSIWYG ACF field, so styling elements instead of classes
    h2 {
      font-size: 32px;
      font-family: $font2;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
      line-height:1.6;
      letter-spacing: 0.025em;
    }

  }



  .culture-circles {
    position: relative;
    display: flex;
    left: -10%;
    top: -200px;
    @media (max-width: 800px) {
      top: 0;
      left: 0;
      display: block;
      //margin: 0 -12.5%
    }
    @media (max-width: 500px ) {
      margin: 0;
    }

    .culture-circle {
      background-size: 100% 100%;
      color: white;
      position: relative;
      width: 400px;
      height: 400px;
      @media (max-width: 800px) {
        //padding-bottom: 60px;
        top: 0 !important;
        margin: auto;
        width: 100%;
        //margin: 10px auto;
      }

      &.mod-first {
        background-image: url("../img/raw/white-circle.svg");
        @media (max-width: 800px) {
          //top: 45px!important;
          background-color: #c0bb86;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          background-image: url("../img/raw/gold-circle.svg");
          background-size: 100% 100%;
          z-index: 2;
          mix-blend-mode: multiply;
        }
      }

      &.mod-second {
        background-image: url("../img/raw/dark-circle.svg");
        left: -10%;

        @media (max-width: 800px) {
          left: 0;
          margin-bottom: 60px;
          background-color: #25282a;
        }

      }

      h2 {
        font-family: font2;
        font-size: 40px;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
        @media (max-width: 800px) {
          position: relative;
          text-align: left;
          &.mod-first:before {
            content: "" ;
            background-image: url(../img/raw/culture-skills.svg);
            position: absolute;
            left: 0;
            width: 40px;
            height: 40px;
            background-size: contain;
            left: -65px;
            top: 50%;
            transform: translateY(-50%);
          }
          &.mod-second:before {
            content: "" ;
            background-image: url(../img/raw/culture-life.svg);
            position: absolute;
            left: 0;
            width: 40px;
            height: 40px;
            background-size: contain;
            left: -65px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      img {
        margin-bottom: 25px;
        max-width: 45px;
        @media (max-width: 800px) {
          display: none;
        }
      }


      ul {
        font-size: 16px;
        list-style-type: none;
        text-align: left;
        width: 100%;
        @media (max-width: 800px) {
        }
      }

      li {
        padding-bottom: 8px;
      }

      .culture-circle-inner {
        position: relative;
        //padding: 150px;
        max-width: 200px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 800px) {
          justify-content: left;
        }
        @media (max-width: 500px) {
          margin-left: 90px;
        }
      }

    }
  }


  .culture-positions {
    max-width: 600px;
    padding-left: 45px;
    @media (max-width: 800px) {
      padding-left: 0;
    }

    font-size: 36px;
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 800px) {
      padding: 30px 30px 80px 30px;
      font-size: 28px;
      padding-left: 0;
    }
    @media (max-width: 500px) {
     padding-left: 30px;
    }


    .culture-position {
      font-family:  $font2;
      position: relative;
      margin-bottom: 30px;


      a {
        transition: transform 0.3s, color 300ms ease-out;
        border-bottom: 1px solid;
        display: block;


        svg {

          transition: transform 300ms ease-out, opacity 300ms ease-out, color 300ms ease-out;

          opacity: 0;
          transform: translateX(-100%) translateY(-50%);

          height: 22px;
          padding-top: 7px;
          width: auto;

          display: inline-block;
          margin-left: 30px;
          position: absolute;
          top: 50%;

          g {
            transition: stroke 300ms ease-out;
            stroke: rgba(white,.9);
          }


          path {
            //fill: white;
          }
        }

        span {
          display: inline-block;
          transition: transform 0.3s, color 300ms ease-out;
        }

        &:hover {
          &:hover svg,
          &:focus svg {
            opacity: 1;
            transform: translateX(0%) translateY(-50%);
            g {
              stroke: rgba(#c0bb87,.95);
            }

          }

          &:hover span,
          &:focus span {
            color: rgba(#c0bb87,.95);
          }
        }

      }

    }
  }




}


.culture-mobile-title {
  font-size: 44px;
  padding: 0 30px;
  text-align: left;
  color: #c0bb87;
  margin-bottom: 0;
  font-family: $font1;
  font-weight: 600;
  margin-top: 60px;
  @media (min-width: 800px) {
    display: none;
  }
}

