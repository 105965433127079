/*------------------------------------*\
  styles

  - individual career page
\*------------------------------------*/


 .career {
   background: #fff;
   .career-hero {
     display: block;
     height: 70vh;
     width: auto;
     position: relative;
     //background-color: #25282a;
     background-color: #25282a;


     &::before {
       position: absolute;
       z-index: 10;
       background: rgba(#25282a, 0.9);
       content: '';
       top: 0;
       left: 0;
       bottom: 0;
       right: 0;
     }
   }

   .career-hero-bg {
     display: block;
     background-size: cover;
     background-position: 50%;
     z-index: 5;
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
   }


   .career-hero-front {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 20;
     display: flex;
     justify-content: center;
     align-content: center;
     align-items: center;
   }


   .career-hero-front-content {
     display: block;
     height: auto;
     width: 100%;
     max-width: 1000px;
     padding: 0 20px;
     text-align: left;
     margin: 0 auto;

   }


   .career-hero-toptitle {
     font-size: 16px;
     line-height: 1.2;
     margin-bottom: 30px;
     letter-spacing: 2px;
     text-transform: uppercase;
     font-weight: 600;
     color: #c0bb87;
   }

   .career-hero-title {
     font-size: 60px;
     font-weight: 400;
     color: rgba(white,1);
     line-height: 0.92;
     font-family: $font2;
     @media (max-width: 800px) {
       font-size: 40px;
     }
   }

   .career-hero-subtitle {
     font-size: 14px;
     font-weight: 400;
     color: rgba(white,1);
     line-height: 1.2;
     margin-top: 30px;
     letter-spacing: 1px;
     text-transform: uppercase;
   }

   .career-details {

     max-width: 1000px;
     margin: auto;
     padding: 40px 20px 160px 20px;
     @media (max-width: 800px) {
       padding-bottom: 20px;
     }
     h2 {
       font-family: $font2;
       font-size: 32px;
       font-weight: normal;
       font-style: normal;
       font-stretch: normal;
       margin-bottom: 24px;
       margin-top: 60px;
     }

     p {
       font-family: $font1;
       font-size: 16px;
       font-weight: 400;
       font-style: normal;
       font-stretch: normal;
       line-height: 1.6;
       letter-spacing: 0.025em;
       text-align: left;
       color: #25282a;
       margin-bottom: 10px;
     }
     ul {
       font-size: 15px;
       list-style-type: none;
     }
     li {
       position: relative;
       padding-left: 45px;
       padding-bottom: 20px;
       font-weight: 400;
       line-height: 1.6;
       letter-spacing: 0.025em;
       font-size: 16px;
       &:before {
         content: '';
         position: absolute;
         width: 20px;
         height: 1px;
         background: #25282a;
         left: 0;
         top: 9px;
       }
     }
   }

   .career-button {
     font-size: 16px;
     margin-top: 45px;
     display: inline-block;
     border: 2px solid;
     padding: 20px;
     font-weight: 600;
   }

   .career-details-inner {
     max-width: 540px;
   }





 }
