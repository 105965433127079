.scroll-down-arrow {
  content: '';
  height: 60px;
  width: 14px;
  display: block;
  z-index: 99;
  position: absolute;
  left: 50%;
  bottom: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: contain;
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
  z-index: 999;
}