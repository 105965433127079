/*------------------------------------*\
  styles
\*------------------------------------*/

$easing-expo: cubic-bezier(1, 0, 0, 1);
$easing-quart: cubic-bezier(0.77, 0, 0.175, 1);


.sidemenu-button-wrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  z-index: 470;

  transition: opacity 300ms ease-in-out;

  &.mod-left {
    right: auto;
    left: 0px;
  }



  body.mod-page-barba-active &,
  body.mod-page-transition &,
  body.page-template-page-skills &,
  body.page-template-page-work &,
  body.mod-menu-open & {
    opacity: 0;
    pointer-events: none;
  }






  @media(max-width: 1200px) {
    display: none;
  }

}






  .sidemenu-button {

    display: block;
    outline: none;
    font-size: 12px;
    padding:  40px 50px 40px 20px;
    color: white;
    letter-spacing: 1.2px;
    outline: none;

    transition: color 200ms ease-in-out;
    font-size: 14px;
    font-weight: 600;
    //background: #eee;
    position: relative;

    &.mod-left {
      padding: 40px 20px 40px 50px;

      .sidemenu-button-text{
        position: relative;
        overflow: hidden;
        display: inline-block;
        &:after {
          content: '';
          position: absolute;
          transition: all .5s ease;
          left: 0;
          width: 100%;
          height: 2px;
          background: rgba(255,255,255,.9);
          transform: scale(0, 1);
          transform-origin: left center;
          bottom: 0;
        }
      }


      &:hover {
        .sidemenu-button-text {
          &:after {
            transform: scale(1, 1);
          }
        }
      }
    }



    &:hover {
      .sidemenu-button-text {
        &:after {
          transform: scale(1, 1);
        }
      }
    }


    .sidemenu-button-text {
      //border-bottom: 2px solid white;
      padding-bottom: 8px;

      position: relative;
      overflow: hidden;
      display: inline-block;
      &:after {
        content: '';
        position: absolute;
        transition: all .5s ease;
        left: 0;
        width: 100%;
        height: 2px;
        background: rgba(255,255,255,.9);
        transform: scale(0, 1);
        bottom: 0;
        transform-origin: right center;
      }



    }

  }




html.mod-middle-near-light-section  {

  .sidemenu-button {
    color: #25282a;
  }

  .sidemenu-button-text.sidemenu-button-text {
    border-bottom-color: #25282a;
    &:after {
      background: rgba(#25282a,.9);
    }
  }

}






.sidemenu {
  text-align: center;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  z-index: 480;
  background: white;

  transform: translateX(100%);
  transition: transform 800ms $easing-quart;

  body.mod-sidemenu-open & {
    transform: translateX(0%);
  }

  display: block;
  height: 100%;

}

  .sidemenu-inner {
    text-align: left;
    transition: opacity 600ms $easing-quart;
    display: block;
    height: 100%;
    opacity: .0;

    body.mod-sidemenu-open & {
      opacity: 1;
    }


    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 1%;
      height: 100%;
    }
  }


    .sidemenu-content {
      padding: 20px 20px 20px 50px;
      overflow: auto;

      display: inline-block;
      vertical-align: middle;
      width: 98%;
      max-height: 100%;
    }



  .sidemenu-title {

    margin-bottom: 30px;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;


    padding-left: 30px;
    //letter-spacing: 1.5px;
    font-family: $font2;
    //text-transform: uppercase;

    color: rgba(#25282a,.9);
  }




    .sidemenu-navs {
      //padding: 100px 10px;
    }


      .sidemenu-nav {
        display: block;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 600;
        margin: 0 80px 0 35px;
        padding: 15px 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        //font-family: $font2;
        color: rgba(#25282a,.9);
        transition: 0.5s ease;

        &:hover {
          color: #c0bb87;
        }
      }







  /*
  .sidemenu-text {
    margin-top: 100px;

    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;

    //letter-spacing: 1.5px;
    //font-family: $font2;
    //text-transform: uppercase;

    color: rgba(#25282a,.9);
  }
  */
