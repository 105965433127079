/*------------------------------------*\
  styles
\*------------------------------------*/



.work {
  background: white;
}



  // hero and section are almost the same

  .work-hero {
    display: block;
    width: auto;
    position: relative;
    background: #111;
    overflow: hidden;

    &:after {
      //content: '';
      //height: 60px;
      //width: 20px;
      //display: block;
      //z-index: 99;
      //position: absolute;
      //left: 50%;
      //bottom: 30px;
      //transform: translateX(-50%);
      //background-size: contain;
      //background-repeat: no-repeat;
      //background-image: url(../img/raw/mobile-hero-arrow.svg);
      //animation: float 6s ease-in-out infinite;
    }

    &.mod-video-playing {


    }

    /*
    &::before {
      position: absolute;
      z-index: 10;
      background: rgba(33, 32, 18, 0.67);
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    */
  }










    .work-hero-bg {
      display: block;
      background-size: cover;
      background-position: 50%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }


    .work-hero-front {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
        height: 100%;
    }


      .work-hero-front-content {
        display: block;
        height: auto;
        width: 100%;
        max-width: 1000px;
        padding: 0 20px;
        text-align: left;
        margin: 0 auto;

        &.mod-video {
          text-align: center;
        }
      }

        .work-hero-title {
          font-size: 120px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 0.92;
          font-family: $font2;
        }

        .work-hero-subtitle {
          font-size: 14px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 1.2;
          margin-top: 30px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }






    .work-header {
      padding: 80px 0 0 0;
    }


      .work-header-inner {
        display: block;
        height: auto;
        width: 100%;
        max-width: 1000px;
        padding: 0 20px;
        text-align: left;
        margin: 0 auto;
      }


        .work-header-title {
          font-size: 90px;
          font-weight: 400;
          color: rgba(#25282a,1);
          line-height: 0.92;
          font-family: $font2;
          position: relative;
          display: inline-block;
          &:after {
           content: '';
            display: block;
            height: 30px;
            width: 190px;
            background-image: url("../img/raw/Wave.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -250px;
          }


          @media(max-width: 1000px){
            font-size: 60px;
            &::before {
              width: 130px;
              height: 30px;
            }
          }

          @media(max-width: 600px){
            font-size: 40px;
            &::after {
              left: 0;
              right: auto;
              opacity: .4;
              top: 120%;
              transform: none;
            }
          }

        }


        .work-header-description {
          font-size: 19px;
          font-weight: 400;
          color: rgba(#25282a,1);
          line-height: 1.5;
          margin-top: 35px;

          letter-spacing: 0.5px;
        }



  .work-pieces {

  }


    .work-pieces-inner {
      display: block;
      width: 100%;
      max-width: 1000px;
      padding: 150px 20px 250px 20px;

      margin: 0 auto;

      @media(max-width: 1100px){
        padding: 20px 20px 60px 20px;
      }


    }






















  .work-hero-play-btn-wrapper {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;


    .work-hero-video-wrapper.mod-embed-visible & {
      opacity: 0;
      pointer-events: none;
      transform: translate(-50%, -50%) scale(.8);
    }


  }

    .work-hero-play-btn {

      //border: 1px solid rgba(white,.5);
      //border-radius: 100px;
      //padding: 20px;
      outline: none;
      display: block;


      svg {
        transform: scale(1.5);
        position: relative;
        left: 10px;
        width: 100px;
        height: auto;
      }
      svg path {
        fill: #25282A;
        transition: fill 200ms ease-in-out 0ms;
      }

      &:focus,
      &:active,
      &:hover {
        svg path {
          fill: #C0BB87;
        }
      }
    }

    .work-hero-play-btn-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      color: #C0BB87;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }






    .work-hero-video-wrapper {
      width: 100%;
      height: 100%;
      transition: opacity 1000ms ease-in-out;


    }




      .work-hero-video {
        width: 100%;
        height: 100%;
        //width: 100% !important;
        //height: 100% !important;
        @media (min-width: 500px) {
          position: relative;
        }

        height: 85vh;

        transition: height 1000ms ease-in-out;
        //.vjs-tech { object-fit: cover; }

        video {
          width: 100%;
          height: 100%;
        }

        .work-hero.mod-video-playing & {
          height: 100vh;
        }

      }


        .work-menu-overlay {
          position: fixed;
          top: 0;
          width: 100%;
          display: block;
          height: 120px;
          z-index: 99;
          pointer-events: none;
          background: transparent;
          transition: 0.3s;

          &.active {
            background: rgba(#25282a, 0.7);
          }
        }
        .work-hero-video {
          &.vjs-playing {
            //height: auto;
          }
          &.vjs-playing, &.vjs-user-active {
            &:after {
              content: '';

            }
          }
      }
