/*------------------------------------*\
  styles
\*------------------------------------*/



.case {
  background: white;
}




  // hero and section are almost the same

  .case-hero {
    display: block;
    height: 100vh;
    width: auto;
    position: relative;
    background: #222;

    // color filter:
    /*
    &::before {
      position: absolute;
      z-index: 10;
      background: rgba(33, 32, 18, 0.67);
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    */
  }

    .case-hero-bg {
      display: block;
      background-size: cover;
      background-position: 50%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @media (max-width: 800px) {
        font-size: 48px;
        line-height: 1.1;
      }
    }


    .case-hero-front {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
    }


      .case-hero-front-content {
        display: block;
        height: auto;
        width: 100%;
        //max-width: 900px;

        max-width: 65vw;
        padding: 0 200px;
        text-align: left;
        //margin: 0 auto;

        @media(max-width: 1500px) {
        }

        @media(max-width: 1200px) {
          padding: 0 50px;
          transform: none !important; // disable parallax
        }

        @media(max-width: 800px) {
          padding: 0 20px;
          max-width: 85vw;
          width: 100vw;
        }

      }


        .case-hero-toptitle {
          font-size: 14px;
          font-weight: 600;
          color: rgba(#c0bb87,1);
          line-height: 1.2;
          margin-bottom: 30px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        .case-hero-title {
          font-size: 70px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 0.92;
          font-family: $font2;

          @media(max-width: 1400px){
            font-size: 50px;
          }
          @media(max-width: 1200px){
            font-size: 40px;
            line-height: 1.1;
          }
          @media(max-width: 1000px){
          }
          @media(max-width: 500px){
          }
        }

        .case-hero-subtitle {
          font-size: 14px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 1.2;
          margin-top: 30px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }














  .case-meta {
    background: #25282a;
  }

    .case-meta-inner {

      display: block;
      margin: auto;
      max-width: 1200px;
      width: 100%;
      padding: 250px 100px 310px 200px;

      display: flex;
      justify-content: space-between;

      @media(max-width: 1000px){
        display: block;
        padding: 100px 20px 150px 20px;
      }

    }

      .case-meta-section {


        &.mod-details {
          width: 30%;
          width: 300px;
          order: 2;
        }

        &.mod-text {
          width: 70%;
          width: calc(100% - 300px);
          order: 1;
        }

        @media(max-width: 1000px){
          &.mod-details,
          &.mod-text {
            width: auto;
            display: block;
          }
        }

      }


      .case-meta-details {
        padding-right: 100px;
        padding-top: 80px;
        @media(max-width: 1000px){
          padding-right: 0px;
          padding-bottom: 80px;
          padding-left: 100px;
          padding-top: 0;
        }
        @media(max-width: 500px){
          padding-right: 0px;
          padding-bottom: 80px;
          padding-left: 0px;
        }
      }

        .case-meta-detail {
          margin-bottom: 50px;
          @media(max-width: 1000px){
            display: inline-block;
            margin-right: 50px;
          }
        }

          .case-meta-detail-title {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.2;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: rgba(white,.9);
          }

          .case-meta-detail-content {
            font-size: 10px;
            font-weight: 400;
            line-height: 1.2;
            text-transform: uppercase;
            color: rgba(white,.4);
            p {
              font-size: 12px;
              line-height: 1.5;
              letter-spacing: 0.1em;
              font-weight: 600;
            }
          }


      .case-meta-text {
        padding-right: 100px;
        position: relative;
        @media(max-width: 1000px){
          padding-right: 0px;
        }
      }

        .case-meta-text-bg-img {
          position: absolute;
          z-index: 4;

          left: 0;
          top: 50%;
          transform: translateY(-50%);
          max-width: 1000px;
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .case-meta-title {
          font-family: $font2;
          font-size: 38px;
          font-weight: 400;
          line-height: 1.2;
          color: rgba(white, 1);
          margin-bottom: 50px;
          position: relative;
          z-index: 5;
          mix-blend-mode: exclusion;
          @media(max-width: 1000px){
            font-size: 34px;
          }
          @media(max-width: 500px){
            font-size: 26px;
          }
        }

        .case-meta-description {
          font-size: 20px;
          font-weight: 400;
          line-height: 1.5;
          color: rgba(white,.7);

          padding-left: 100px;
          position: relative;
          z-index: 5;
          mix-blend-mode: exclusion;

          p {
            margin-top: 30px;
          }

          @media(max-width: 500px){
            padding-left: 0px;
          }
        }








  // IMAGE
  .case-img.case-img {
    &.mod-big-res {
      display: block;
    }
    &.mod-small-res {
      display: none;
    }
  }


  @media(max-width: 1000px){
    .case-img.case-img {
      &.mod-big-res {
        display: none;
      }
      &.mod-small-res {
        display: block;
      }
    }
  }









  // full width image

  .case-fwimage-top-margin {
    height: 200px;
    height: 10vw;
  }

  .case-fwimage-wrapper {
    position: relative;
    .case-meta + & {
      margin-top: 0px;
    }
  }

    .case-fwimage {
      display: block;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

    }


    .case-fwimage-overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 5;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &.mod-first {
        z-index: 6;

      }

      &.mod-second {
        z-index: 7;

      }

      &.mod-third {
        z-index: 8;

      }
    }





  // contained mage
  .case-conimage {
    display: block;
    text-align: center;
    padding: 0 100px;

    margin-top: 200px;
    margin-top: 10vw;

    @media(max-width: 1200px){
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: 1000px){
      padding-left: 0px;
      padding-right: 0px;
    }

    .case-conimage-inner {

      display: block;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

  }






  // double image
  .case-dimage {
    padding: 0px 100px;

    margin-top: 200px;
    margin-top: 10vw;

    @media(max-width: 1200px){
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: 1000px){
      padding-left: 0px;
      padding-right: 0px;
    }
  }

    .case-dimage-inner {

      display: block;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }


    .case-dimage-image-wrapper {
      width: 45%;

      img {
        width: 100%;
        height: auto;
      }
    }









































  .case-slider {
    font-size: 16px;
    padding: 0 100px;
    padding-bottom: 70px; // account for bullet


    margin-top: 200px;
    margin-top: 10vw;

    &.mod-big-res {
      display: block;
    }
    &.mod-small-res {
      display: none;
    }


    @media(max-width: 1200px){
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: 1000px){
      padding-left: 0px;
      padding-right: 0px;
    }

  }

  @media(max-width: 1000px){
    .case-slider.case-slider {
      &.mod-big-res {
        display: none;
      }
      &.mod-small-res {
        display: block;
      }
    }
  }



    .case-slider-inner {

      width: 100%;
      max-width: 1200px;
      margin: 0px auto;
      position: relative;
      //height: 700px;

    }

      .case-slider-container {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        border: 1px solid rgba(#c0bb87,.2);

      }


        .case-slider-slide {
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: auto;
            //object-fit: cover;
            //object-position: 50% 50%;
          }
        }



      .case-slider-button {

        background: none;
        //border: 2px solid rgba(#25282a,.2);
        background-color: #c0bb87;
        width: 80px;
        height: 40px;
        //border-radius: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        transition: opacity 200ms ease-in-out;

        transition: 0.5s;
        @media (max-width: 800px) {
          display: none;
        }

        svg {
          width: 25px;
          height: auto;
          transition: 0.5s;
          path {
            fill: rgba(white,.8);
          }
        }

        &.mod-prev {
          left: -40px;;
          &:hover {
            width: 120px;
            left: -80px;
          }
        }

        &.mod-next {
          right: -40px;
          &:hover {
            width: 120px;
            right: -80px;
          }
        }

        &.swiper-button-disabled {
          opacity: 0;
        }


        @media(max-width: 1400px){
          &.mod-prev {
            left: -10px;;
          }

          &.mod-next {
            right: -10px;
          }
        }


        @media(max-width: 1000px){
          background-color: rgba(#c0bb87,.5);
          &.mod-prev {
            left: 20px;;
          }

          &.mod-next {
            right: 20px;
          }

        }


      }





      .case-slider-pagination-wrapper {
        display: block;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;

        bottom: -45px;
      }



        .case-slider-pagination {
          left: 50%;
          transform: translateX(-50%);

          .swiper-pagination-bullet  {
            margin: 0 10px;
            width: 12px;
            height: 12px;
            transition: all 100ms ease-in-out;
            transform: scale(0.8);
          }

          .swiper-pagination-bullet-active {
            background: rgba(#25282a,.8);
            transform: scale(1);
          }

        }
























  .case-video {
    display: block;
    width: auto;
    position: relative;
    padding: 0 100px;

    margin-top: 200px;
    margin-top: 10vw;


    @media(max-width: 1200px){
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: 1000px){
      padding-left: 0px;
      padding-right: 0px;
    }
  }

    .case-video-inner {
      max-width: 1200px;
      margin: 0px auto;
      position: relative;
      //height: 731px;
      //height: 542px;
      background: lighten(#25282a,5);

      /*
      &::before {
        position: absolute;
        z-index: 10;
        background: rgba(33, 32, 18, 0.67);
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      */
    }




  .case-video-play-btn-wrapper {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;


    .case-video.mod-embed-visible & {
      opacity: 0;
      pointer-events: none;
      transform: translate(-50%, -50%) scale(.8);
    }


  }


    .case-video-play-btn {

      //border: 1px solid rgba(white,.5);
      //border-radius: 100px;
      //padding: 20px;
      outline: none;


      svg {
        transform: scale(1.5);
        position: relative;
        left: 10px;
        width: 100px;
        height: auto;
      }
      svg path {
        fill: #25282A;
        transition: fill 200ms ease-in-out 0ms;
      }

      &:focus,
      &:active,
      &:hover {
        svg path {
          fill: #C0BB87;
        }
      }
    }

    .case-video-play-btn-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      color: #C0BB87;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
    }




    .case-video-video-wrapper {
      width: 100%;
      height: 100%;

    }


      .case-video-video {
        width: 100%;
        height: 100%;
        //width: 100% !important;
        //height: 100% !important;
        position: relative;

        video {
          width: 100%;
          height: 100%;
        }

      }










.case-nav {
  min-height: 300px;
  padding: 0 0 50px 0;
  //background: #25282a;

  background-image: url("../img/raw/related.svg");
  background-position: center 40%;
  margin-top: 200px;
  margin-top: 10vw;
  @media (max-width: 800px) {
    background-image: none;
  }
}

  .case-nav-inner {
    display: flex;
    justify-content: center;
  }

    .case-nav-section {
      display: inline-block;
      width: 50%;
      padding: 150px 50px;
      text-align: center;

      max-width: 500px;


      &.mod-first {
        text-align: right;
        border-right: 1px solid rgba(#25282a,.1);
        &:hover {
          .case-nav-icon {
            transform: translateX(-30px);
          }
        }
      }

      &.mod-last {
        text-align: left;
        &:hover {
          .case-nav-icon {
            transform: translateX(30px);
          }
        }
      }



        @media(max-width: 1200px){
        }
        @media(max-width: 1000px){
        }
        @media(max-width: 800px){
          padding: 100px 20px;
        }
        @media(max-width: 600px){
        }



    }

      .case-nav-icon {
        transition: 0.5s;
        svg {
          position: relative;
          height: 40px;
          width: auto;
        }
        svg path {
          fill: rgba(#25282a,.9);
        }

        .case-nav-section.mod-first & svg {
          left: 5px;
        }

        .case-nav-section.mod-last & svg {
          left: -7px;
        }

        @media(max-width: 1200px){
        }
        @media(max-width: 1000px){
          svg {
            height: 30px;
          }
        }
        @media(max-width: 800px){
          svg {
            height: 20px;
          }
        }
        @media(max-width: 600px){
        }

      }

      .case-nav-toptitle {
        margin-top: 20px;
        //font-family: $font2;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: rgba(#c0bb87,1);
        line-height: 1.2;


        @media(max-width: 1200px){
        }
        @media(max-width: 1000px){
        }
        @media(max-width: 800px){
          font-size: 14px;
        }
        @media(max-width: 600px){
          font-size: 11px;
        }
      }

      .case-nav-title {
        //margin-top: 20px;
        font-family: $font2;
        margin-top: 20px;
        font-size: 30px;
        font-weight: 400;
        color: rgba(#25282a,1);
        line-height: 1.2;


        @media(max-width: 1200px){
          font-size: 28px;
        }
        @media(max-width: 1000px){
          font-size: 24px;
        }
        @media(max-width: 800px){
          font-size: 20px;
        }
        @media(max-width: 600px){
          font-size: 18px;
        }
      }


.case-mobile-title {
  font-size: 44px;
  padding: 0 30px;
  text-align: left;
  color: #c0bb87;
  margin-bottom: 0;
  font-family: $font1;
  font-weight: 600;
  margin-top: 10vw;
  @media (min-width: 800px) {
    display: none;
  }
}

