/*------------------------------------*\
  styles
\*------------------------------------*/


  .hamburger-wrapper {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 610;

    transition: opacity 300ms ease-in-out;

    body.mod-sidemenu-open & {
      opacity: 0;
      pointer-events: none;
    }

    @media(max-width: 800px) {
      right: 20px;
      top: 20px;
    }

  }


    // https://codepen.io/bennettfeely/pen/twbyA

    // Change to watch in slow motion
    $duration: 400ms;

    $barHeight: 3px;
    $barRadius: 1px;

    $toggled-size : 1;

    $bg : #274380;
    $nav-bg  : #25282a;
    $content-bg : #FFF;

    .hamburger {
      display: block;
      position: relative;
      transition: $duration/2;
      cursor: pointer;
      user-select: none;
      min-height: 20px;

      outline: none;

      &:before, &:after {
        transition: $duration/2;
      }

      &:hover {
        transition: $duration;

        &:before, &:after {
          transition: $duration/2;
        }

      }
    }



      .hamburger-bun {
        display: block;
        position: relative;
        z-index: 2;
        height: $barHeight;
        width: 27px;
        background: $content-bg;
        border-radius: $barRadius;
        transition: all $duration $duration/2;
      }


      .hamburger-bun.mod-first,
      .hamburger-bun.mod-last {
      }

      .hamburger-bun.mod-first {
        top: -4px;
        left: 6px;
        width: 14px;
      }

      .hamburger-bun.mod-center {
        top: 0;
        left: 3px;
        width: 20px;
      }

      .hamburger-bun.mod-last {
        top: 4px;
        left: 0;
        width: 27px;
      }








    html.mod-top-near-light-section  {

      .hamburger-bun {
        background: #25282a;
      }

      .hamburger-bun.mod-first,
      .hamburger-bun.mod-last {
        background: #25282a;
      }

    }







    body.mod-menu-open {


      .hamburger {
        transform: scale($toggled-size) rotate(90deg);
      }

      .hamburger-bun {
        background: #25282a;
        transition: all $duration;
        transform: scale($toggled-size);
      }


      .hamburger-bun.mod-first,
      .hamburger-bun.mod-last {
        background: #25282a;
        transition: all $duration;
        top: 0;
      }



      .hamburger-bun.mod-first {
        left: 0;
        width: 27px;
        transform: translateY(3px) rotate(45deg);
      }


      .hamburger-bun.mod-center {
        transform: scale(0);
      }


      .hamburger-bun.mod-last {
        left: 0;
        width: 27px;
        transform: translateY(-3px) rotate(-45deg);
      }




    }
