/*------------------------------------*\
  styles
\*------------------------------------*/



.dashvideo.dashvideo {

  .vjs-poster {
    transition: opacity 500ms ease-in-out;
    opacity: 1;
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background-size: cover;

  }

  .vjs-has-started .vjs-poster {
    opacity: 0;
    pointer-events: none;
  }

  .vjs-caption-settings,
  .vjs-control,
  .vjs-time-divider {
    display: none;
  }


  .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  .vjs-control-bar {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-in-out;

    height: 200px;
    background: rgba(#25282a,.5);
    background: linear-gradient(to top, rgba(#25282a,.2), rgba(#25282a,.0));
  }


  &:hover .vjs-has-started .vjs-control-bar {
    opacity: 1;
    pointer-events: auto;
  }

  .vjs-user-inactive.vjs-user-inactive .vjs-control-bar {
    opacity: 0;
  }


  .vjs-progress-control {
    display: block;
    left: 25%;
    width: 75%;
    width: calc(75% - 50px);
    right: 50px;
  }


  .vjs-seek-handle {
    cursor: pointer;
    width: 0px;
    height: 100%;
    background: red;
  }


  .vjs-duration,
  .vjs-current-time {
    display: block;
    font-size: 15px;
    color: rgba(white,.8);
    position: absolute;
    bottom: 30px;
    right: 50px;
    width: auto;
    text-align: right;
    height: auto;
    display: none;
  }
  .vjs-duration {
    display: block;
  }
  .vjs-current-time {
    display: none;
  }
  .vjs-has-started .vjs-duration,
  .vjs-playing .vjs-duration {
    display: none;
  }
  .vjs-has-started .vjs-current-time,
  .vjs-playing .vjs-current-time {
    display: block;
  }




  .vjs-play-control {
    cursor: pointer;
    display: block;
    font-size: 16px;
    color: rgba(white,.8);
    position: absolute;
    bottom: 100px;
    right: 90px;
    width: 20px;
    height: 20px;
    //border: 1px solid rgba(white,.7);
    //border-top: none;
    //border-bottom: none;
    border: none;
    background-size: 100% 100%;
    background-position: 50%;
    background-image: url('../img/icon/icons8-pause-filled.svg');

    &.vjs-paused {
      background-image: url('../img/icon/icons8-play-filled.svg');
    }

  }


  .vjs-fullscreen-control {
    cursor: pointer;
    display: block;
    font-size: 16px;
    color: rgba(white,.8);
    position: absolute;
    bottom: 100px;
    right: 50px;
    width: 20px;
    height: 20px;
    //border: 1px solid rgba(white,.7);

    border: none;
    background-size: 100% 100%;
    background-position: 50%;
    background-image: url('../img/icon/icons8-full-screen-filled.svg');

  }


}



.dashvideo {
  font-size: 16px;




  /* Slider - used for Volume bar and Seek bar */
    .vjs-slider {
    /* Replace browser focus highlight with handle highlight */
    outline: 0;
    position: relative;
    cursor: pointer;
    padding: 0;
    /* background-color-with-alpha */
    background: rgba(white,.2);
  }
    .vjs-slider-handle {
    position: absolute;
    /* Needed for IE6 */
    left: 0;
    top: 0;
  }
  /* General styles for individual controls. */
    .vjs-control {
    outline: none;
    position: relative;
    float: left;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 3.0em;
    width: 4em;
  }
  /* Font button icons */
    .vjs-control:before {
    font-family: VideoJS;
    font-size: 1.5em;
    line-height: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
    .vjs-control:focus:before,
    .vjs-control:hover:before {
  }

    .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  /* Progress
  --------------------------------------------------------------------------------
  */
    .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 50px;
    bottom: 74px;
    width: auto;
    font-size: 0.3em;
    height: 3px;
  }
   :hover .vjs-progress-control {
    font-size: .9em;

  }
    .vjs-progress-holder {
    height: 100%;
  }
  /* Progress Bars */
    .vjs-progress-holder .vjs-play-progress,
    .vjs-progress-holder .vjs-load-progress,
    .vjs-progress-holder .vjs-load-progress div {
    position: absolute;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    /* updated by javascript during playback */

    width: 0;
    /* Needed for IE6 */
    left: 0;
    top: 0;
  }
    .vjs-play-progress {
      background: rgba(white,.5);
  }
    .vjs-load-progress {
      background: rgba(white,.3);
  }
  /* there are child elements of the load progress bar that represent the
     specific time ranges that have been buffered */
    .vjs-load-progress div {
    background: #787878 /* IE8- Fallback */;
    background: rgba(255, 255, 255, 0.1);
  }
    .vjs-seek-handle {
    width: 1.5em;
    height: 100%;
  }
    .vjs-seek-handle:before {
    padding-top: 0.1em /* Minor adjustment */;
  }




}
