/*------------------------------------*\
  styles
\*------------------------------------*/


  .barba-transition-element {
    position: fixed;
    z-index: 9;
    background: rgba(#25282a,1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    //transform: translateY(-100%);
    will-change: transition;
    backface-visibility: hidden;
    //pointer-events: none;
    display: none;

  }



  #barba-wrapper {

  }


    .barba-container {
      transition: opacity 400ms ease-in-out;
    }
