/*------------------------------------*\
  styles
\*------------------------------------*/

  .logowrap {
    display: block;
    position: fixed;
    left: 50px;
    top: 50px;
    z-index: 605;
    cursor: pointer;

    svg {
      width: 80px;
      height: auto;
      path {
        transition: fill 400ms ease-in-out;
        fill: #fff;

      }
    }

    body.mod-menu-open & {
      svg path {
        fill: #25282a;
      }
    }


    html.mod-top-near-light-section & {
      svg path { fill: #25282a; }
    }


    @media(max-width: 800px) {
      left: 20px;
      top: 20px;
    }


  }
