/*------------------------------------*\
  styles
\*------------------------------------*/


.skillvert {
  //background: white;
  display: inline-block;
  width: 100%;
  position: relative;
  color: #25282a;
  transition: 0.45s;
  transition-delay: 0s;
  @media (max-width: 1000px){
    flex:  50%;
  }
  @media (max-width: 500px){
   flex: 0 0 100%;
    border-bottom: 1px solid rgba(#25282a, 0.3);
  }


  &.mod-desktop {
    @media (max-width: 500px) {
      display: none;
    }
  }

  &.mod-mobile {
    @media (min-width: 500px) {
      display: none;
    }

    .skillvert-content-inner{
      padding: 0 0 30px 120px;
    }

    .skillvert-content {
      max-height: 0;
      overflow: hidden;
      transition: 0.45s;
      color: #c0bb87;
    }

    .skillvert-arrow {
      transition: 0.3s;
    }

    &.open {
      color: #c0bb87;
      &:before {
        background: #212427;
      }
      .skillvert-content {
        max-height: 500px;
        position: relative;
      }
      .skillvert-icon svg {
        fill: #c0bb87;
      }

      .skillvert-arrow {
        transform: rotate(180deg);
        svg, g {
          stroke: #c0bb87;

        }

      }
      .skillvert-icon {
        svg {
          &, g, circle, rect, path {
            fill: #c0bb87;
          }
        }
      }
    }
  }


  .skillvert-head {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 35px 30px;
    cursor: pointer;

    .skillvert-icon {
      flex: 0 0 60px;
    }
  }

  //border-right: 1px solid darken(white,5);

  &:first-of-type {

    //padding-left: 5%;
    //border-left: 1px solid darken(white,5);
  }

  &:last-of-type {
  //  border-right: none;
  //  padding-right: 5%;
  }

  &.mod-open {
    //background: gray;
  }

  &:hover {
    @media (min-width: 500px) {
      transition-delay: 0.3s;
      color: #c0bb87;
    }
    &:before {
      @media (min-width: 500px) {
      //opacity: 0;
        transform: translateX(100%);
        transition-delay: 0.3s;
      }
    }
    svg {
      &, path, rect {
        @media (min-width: 500px) {
          fill: #c0bb87;
        }
      }
      path, rect{
        @media (min-width: 500px) {
          transition-delay: 0.3s;
        }
      }
    }
  }

  .skillvert-icon {
    svg, path, rect{
      fill: #25282a;
      transition: 0.3s;
      @media (min-width: 500px) {
        transition-delay: 0s;
      }
    }
  }


  &:before {
   content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 101%;
    background: white;
    transition: 0.45s;
    transform: translateX(0);
    transition-timing-function: cubic-bezier(1, -0.04, 0.45, 0.81);
    transition-delay: 0s;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

}


  .skillvert-inner {
    //min-height: 75vh;
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    //align-items: center;
    //align-content: center;
    text-align: center;
    padding: 125px 20% 75px;
    position: relative;
    z-index: 15;
    .skillvert.mod-open & {
      //text-align: left;
    }

    @media (max-width: 500px) {
      transform: translateY(0)!important;     // Disables ScrollR
      min-height: 0;
      padding: 0;
    }

  }

    .skillvert-title {
      display: block;
      width: 100%;
      font-size: 36px;
      font-weight: 400;
      line-height: 1;
      //color: rgba(#25282a,1);
      font-family: $font2;
      padding-bottom: 20px;



      @media (max-width: 500px) {
        padding-bottom: 0;
        padding-left: 30px;
        text-align: left;
        font-size: 24px;
      }



      .skillvert.mod-open & {
        //color: rgba(#c0bb87,1);
      }
    }

    .skillvert-content {
      display: block;
      text-align: left;
      width: 100%;
      font-size: 14px;
      letter-spacing: .025em;
      font-weight: 400;
      //color: rgba(#c0bb87,1);
      line-height: 1.4;
      height: auto;
      //font-family: $font2;

      br {
        line-height: 2.6;
      }

      .skillvert.mod-open & {
        //height: auto;
        //color: rgba(#c0bb87,1);
      }
    }


    .skillvert-icon {
      display: block;
      margin: 0 auto;
      padding-bottom: 40px;
      @media (max-width: 500px) {
        padding-bottom: 0;
      }
      .skillvert.mod-open & {
        //margin: 0 auto 0 0;
      }

      svg {
        margin-top: 20px;
        display: block;
        width: 100%;
        max-width: 100px;
        max-height: 35px;
        transition: 0.3s;
        @media (max-width: 500px) {
          margin-top: 0;
        }
      }
    }
