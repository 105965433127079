/*------------------------------------*\
  styles
\*------------------------------------*/


.home {

}


  // hero and section are almost the same

  .home-hero {
    display: block;
    height: 100vh;
    width: auto;
    position: relative;
    overflow: hidden;


    @media(max-width: 1200px) {
      //height: 600px;
    }

    @media(max-width: 1100px) {
      //height: 500px;
    }

    &:after {
      //content: '';
      height: 60px;
      width: 20px;
      display: block;
      z-index: 99;
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../img/raw/mobile-hero-arrow.svg);
      animation: float 6s ease-in-out infinite;
    }

  }



    .home-hero-video-wrapper {
      display: block;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      height: 100vh;
      @media (max-width: 800px) {
        display: none;
      }
    }


      .home-hero-video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        height: 100vh;
        background: rgba(#25282a,.2);
        display: block;
        z-index: 5;

      }

      .home-hero-video {
        position: relative;
        z-index: 4;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }





    .home-hero-bg {
      display: block;
      background-size: cover;
      background-position: 50%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;



    }


    .home-hero-front {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

    }


      .home-hero-front-content {
        display: block;
        height: auto;
        width: 100%;
        //max-width: 1100px;
        padding: 0 200px;
        text-align: left;
        //margin: 0 auto;



        @media(max-width: 1500px) {
        }

        @media(max-width: 1200px) {
          padding: 0 50px;
          transform: none !important; // disable parallax
        }

        @media(max-width: 800px) {
          padding: 0 20px;
        }

      }

        .home-hero-title {
          font-size: 120px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 0.92;
          font-family: $font2;
          will-change: opacity;
          backface-visibility: hidden;

          @media(max-width: 1400px){
            font-size: 100px;
          }
          @media(max-width: 1200px){
            font-size: 80px;
          }
          @media(max-width: 1000px){
            font-size: 60px;
          }
          @media(max-width: 500px){
            font-size: 48px;
          }
        }

        .home-hero-subtitle {
          font-size: 22px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 1.3;
          letter-spacing: 1px;
          margin-top: 30px;
          will-change: opacity;
          backface-visibility: hidden;

          @media(max-width: 1200px){
            font-size: 20px;
          }
          @media(max-width: 800px){
            font-size: 16px;
          }
        }





  // hero and section are almost the same

  .home-section {
    display: block;
    height: 100vh;
    width: auto;
    position: relative;
    overflow: hidden;

    @media(max-width: 1200px) {
      //height: 600px;
    }

    @media(max-width: 1100px) {
      //height: 500px;
    }



  }

    .home-section-bg {
      display: block;
      background-size: cover;
      background-position: 50%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

      .home-section-bg-img {
        background-size: cover;
        background-position: 50%;
        display: block;
        width: 100%;
        height: 100%;
      }


    .home-section-front {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
    }

      .home-section-front-content {
        display: block;
        height: auto;
        width: 100%;
        //max-width: 1100px;
        max-width: 65vw;
        @media (max-width: 500px) {
          max-width: 95vw;
        }
        padding: 0 200px;
        text-align: left;
        //margin: 0 auto;
        @media(max-width: 1500px) {
        }

        @media(max-width: 1200px) {
          padding: 0 50px;
          transform: none !important; // disable parallax
        }

        @media(max-width: 800px) {
          padding: 0 20px;
        }
      }


        .home-section-title {
          font-size: 70px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 1.1;
          font-family: $font2;
          will-change: opacity;
          backface-visibility: hidden;

          @media(max-width: 1400px){
            font-size: 50px;
          }
          @media(max-width: 1200px){
            font-size: 40px;
          }
          @media(max-width: 1000px){
            font-size: 38px;
          }
          @media(max-width: 500px){
            //font-size: 30px;
          }
        }

        .home-section-subtitle {
          font-size: 20px;
          font-weight: 400;
          color: rgba(white,1);
          line-height: 1.6;
          letter-spacing: 1px;
          margin-top: 25px;
          @media (max-width: 500px) {
            margin-top: 18px;
          }
          will-change: opacity;
          backface-visibility: hidden;


          @media(max-width: 1200px){
            font-size: 20px;
          }
          @media(max-width: 800px){
            font-size: 16px;
          }
        }


        .home-section-link {
          margin-top: 90px;
          will-change: opacity;
          backface-visibility: hidden;

          @media(max-width: 1200px){
            margin-top: 30px;
          }
        }


        .home-section-link-a {
          padding: 20px 0;
          display: inline-block;
          span {
            font-size: 24px;
            font-weight: 400;
            color: rgba(white,.85);
            line-height: 1.3;
            display: inline-block;
            font-family: $font2;
            transition: all 300ms ease-out;
            @media (max-width: 800px) {
              font-size: 18px;
            }


          }

          svg {

            transition: all 300ms ease-out;

            opacity: 0;
            transform: translateX(-100%);

            height: 22px;
            padding-top: 7px;
            width: auto;

            display: inline-block;
            margin-left: 30px;

            g {
              stroke: rgba(white,.9);
            }


            path {
              //fill: white;
            }
          }


          &:hover svg,
          &:focus svg {
            opacity: 1;
            transform: translateX(0%);
            g {
              stroke: rgba(white,.95);
            }

          }

          &:hover span,
          &:focus span {
            color: rgba(white,.95);
          }
        }




    .home-logogrid {
      display: block;
      position: relative;
      padding: 300px 0 100px 0;
      @media (max-width: 500px) {
        padding: 100px 0 100px 0;
      }
    }


      .home-logogrid-bg-text-wrapper {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 50;
        pointer-events: none;
        user-select: none;
        mix-blend-mode: overlay;
        text-align: left;

        @media(max-width: 600px){
          top: 10%;
          transform: translateX(-50%);
          display: none;
        }


      }

      .home-logogrid-bg-text {
        display: block;

        span {
          font-size: 300px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: .85;
          opacity: 1;
          color: #1d1c15;

          @media(max-width: 1400px){
            font-size: 250px;
          }
          @media(max-width: 1200px){
            font-size: 200px;
          }
          @media(max-width: 1000px){
            font-size: 140px;
          }
          @media(max-width: 500px){
            font-size: 80px;
          }
        }

      }

      .home-mobile-title {
        font-size: 50px;
        padding: 0 30px;
        text-align: left;
        color: #c0bb87;
        margin-bottom: 0;
        font-family: $font1;
        font-weight: 600;
        @media (min-width: 500px) {
          display: none;
        }
      }


      .home-logogrid-inner {
        display: block;
        width: 100%;
        max-width: 1200px;
        padding: 0 100px;
        text-align: center;
        margin: 0 auto;

        html.mod-browser-ie &,
        html.mod-browser-edge & {
          position: relative;
          z-index: 60;
        }


        @media(max-width: 1200px){
          padding: 0 20px;
        }
      }


        .home-logogrid-items {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;

          @media(max-width: 600px){
            //display: block;
          }

        }


        .home-logogrid-item {
          width: 33.33%;
          min-height: 200px;
          @media (max-width: 500px) {
           min-height: 100px;
          }
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: auto;
            max-width: 200px;
          }


          @media(max-width: 1200px){
            width: 49.99%;
            display: inline-block;
            padding: 0 10px;
          }

          @media(max-width: 600px){
            //display: block;
            //width: auto;
          }


        }
