/*------------------------------------*\
  styles
\*------------------------------------*/


.abpoint {
  padding: 200px 20px 200px 20px;
    @media (max-width: 700px) {
      padding: 0;
    }
  position: relative;


  &:after {
    //content: '';
    //display: block;
    //height: 30px;
    //width: 190px;
    //background-image: url("../img/raw/Wave.svg");
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    //position: absolute;
    //top: 50%;
    //transform: translateY(-50%);
    //left: 50%;
  }

}

  .abpoint-sep {
    position: absolute;


    top: 0;
    left: 50%;
    transform: translate(-0%, -50%);
    width: 100px;
    background: rgba(rgb(193, 187, 134),.9);
    height: 2px;

    @media (max-width: 700px) {
      //position: relative;
      //left: 0;
      //width: auto;
      display: none;
    }
  }


  .abpoint-inner {
    margin: 0px auto;
    max-width: 960px;
    position: relative;
  }

    .abpoint-backdrop {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0%;
      z-index: 2;
      opacity: .5;
      @media (max-width: 700px) {
        position: relative;
        top: 0;
        opacity: 1;

      }
    }


      .abpoint-backdrop-inner {
        position: relative;
        color: rgba(20, 21, 0, 0.38);
        color: rgba(#19180f, 1);
        font-size: 200px;
        line-height: 0.8;
        font-weight: 600;
        margin-top: 50px;
        user-select: none;

        @media (max-width: 700px) {
          font-size: 38px;
          color: #c0bb87;
        }

      }

.abpoint-backdrop-inner {
  br {
    @media (max-width: 700px) {
      display: none;
    }
  }
}

    .abpoint-front {
      mix-blend-mode: overlay;
      z-index: 5;
      position: relative;
    }

    .abpoint-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

      .abpoint-image {
        width: 50%;
        height: 200px;
        background-size: contain;
        background-position: 50%;
        @media (max-width: 700px) {
          display: none;
        }
      }

      .abpoint-title {
        width: 50%;
        font-size: 50px;
        font-weight: 400;
        color: rgba(white,1);
        line-height: 1.2;
        font-family: $font2;


        @media(max-width: 1200px){
          font-size: 45px;
        }
        @media(max-width: 1000px){
          font-size: 44px;
        }
        @media(max-width: 700px){
          width: 100%;
          font-size: 40px;

        }
        @media(max-width: 500px){
          //font-size: 35px;
        }


      }

    .abpoint-desc {
      width: 50%;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 400;
      color: rgba(white,.8);
      letter-spacing: 0.5px;
      line-height: 1.6;
      margin-top: 30px;
      //letter-spacing: 2px;
      //text-transform: uppercase;
      @media (max-width: 700px){
        width: 100%;

      }
    }


.abpoint-mobile-seperator {
padding: 60px 0;

  @media (min-width: 700px) {
    display: none;
  }
}