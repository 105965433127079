/*------------------------------------*\
  styles
\*------------------------------------*/


.workpiece {
  display: inline-block;
  width: 50%;
  max-width: 600px;

  padding-right: 10px;


  margin-top: -100px;
  &.mod-alt {
    padding-right: 0;
    padding-left: 10px;
    margin-top: 100px;

  }



  @media(max-width: 1100px){
    display: block;
    width: auto;

    transform: none !important;

    max-width: none;
    margin: 0;
    padding: 0;
    padding: 0 0px;
    margin: 40px 0;

    &.mod-alt {
      margin: 0;
      padding: 0;
      padding: 0 0px;
      margin: 40px 0;

    }

  }

}

  .workpiece-inner {
    //border: 1px solid darken(white,15);
    //background: darken(white,15);

    //&:hover {
    //  background: #25282a;
    //  border: 1px solid darken(#25282a,15);
    //}
    &:hover {

      .workpiece-image img {
        transform: scale(1.1);
      }
    }
  }


  .workpiece-image {
    //width: 100%;
    height: 500px;
    width: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 1s;
     transition-timing-function: cubic-bezier(0.32, 0.01, 0, 1);

    }

    @media(max-width: 600px){
      height: 300px;
    }


  }



  .workpiece-label {
    display: flex;
    justify-content: space-between;
    height: 150px;
    background: white;
    backface-visibility: hidden;

    border: 1px solid darken(white,15);
    border-top: none;

    transition: all 100ms ease-out;

    .workpiece-inner:hover & {
      background: #25282a;
      border-color: darken(#25282a,15);


    }


    @media(max-width: 1100px){
      height: auto;
    }

    @media(max-width: 600px){
      display: block;
      width: auto;
      border: none;
      border-left: 1px solid darken(white,15);
      border-right: 1px solid darken(white,15);
      border-bottom: 1px solid darken(white,15);

      .workpiece-inner:hover & {
        border-color: rgba(white,.2);
      }
    }

  }



    .workpiece-label-section {
      display: inline-block;
      width: 50%;

      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;

      padding: 10px 20px;
      transition: all 100ms ease-out;

      @media (max-width: 500px) {
        padding: 30px 20px;
      }

      &.mod-title {
        width: 60%;
        border-right: 1px solid rgba(#25282a,.2);
        @media (max-width: 500px) {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }

      .workpiece-inner:hover & {
        &.mod-title {
          border-right: 1px solid rgba(white,.2);
        }
      }


      @media(max-width: 600px){
        display: block;
        width: auto;
        width: 100%;
        border: none;

        &.mod-title {
          padding-top: 20px;
          padding-bottom: 20px;
          min-height: 70px;
          border: none;
          width: 100%;
          border-bottom: 1px solid darken(white,15);

        }

        .workpiece-inner:hover &.mod-title {
          border-color: rgba(white,.2);
        }

      }

    }


      .workpiece-label-title {
        font-family: $font2;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(#25282a,1);
        .workpiece-inner:hover & {
          color: rgba(white,1);
        }
      }


      .workpiece-label-company {
        //font-family: $font2;
        display: block;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.2;
        color: rgba(#25282a,1);
        text-transform: uppercase;
        transition: all 100ms ease-out;
        .workpiece-inner:hover & {
          color: rgba(white,1);
        }
      }

      .workpiece-label-skills {
        //font-family: $font2;
        display: block;
        margin-top: 5px;
        width: 100%;
        font-size: 10px;
        letter-spacing: 0.1em;
        font-weight: 400;
        line-height: 1.5;
        color: rgba(#25282a,.7);
        text-transform: uppercase;
        transition: all 100ms ease-out;
        .workpiece-inner:hover & {
          color: rgba(white,.7);
        }
      }
