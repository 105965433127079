/*------------------------------------*\

  Base Styles

  * It should only include
  tag selectors.

  * Class & ID selectors are
  forbidden here.

\*------------------------------------*/

:root {
  font-variant-ligatures: no-common-ligatures;
  color: #333;
  font-family: $font1;
  background: #25282a;
  font-size: 0;
}
:-webkit-full-screen {
  background: lighten(black,2);
}

body {

  overflow-y: scroll;


  &.mod-sidemenu-open,
  &.mod-menu-open {

    overflow: hidden;
  }
}


.dark ::selection {
 background: #c0bb86!important;
}


// debug
//*[js-light-section] {
//  outline: 1px dashed red;
//}
