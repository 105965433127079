/*------------------------------------*\
  styles
\*------------------------------------*/



.contact {
//background: #222426;
}



.contact-hero {
  display: block;
  min-height: 100vh;
  width: auto;
  position: relative;
  //background-color: #25282a;
  background-color: #25282a;

  html.mod-browser-ie &,
  html.mod-browser-edge & {
    z-index: 60;
  }

  &::before {
    position: absolute;
    z-index: 10;
    background: rgba(#25282a, 0.9);
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &:after {
    content: '';
    background: url("../img/raw/mobile-hero-arrow.svg");
    @media (max-width: 500px) {
      height: 60px;
      width: 10px;
      display: block;
      z-index: 99;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.contact-hero-bg {
  display: block;
  background-size: cover;
  background-position: 50%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}


.contact-hero-front {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.contact-hero-front-content {
  display: block;
  height: auto;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  text-align: left;
  margin: 0 auto;

}


.contact-hero-toptitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(#c0bb87,1);
  line-height: 1.2;
  margin-bottom: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media(max-width: 500px){
    font-size: 12px;
  }

}

.contact-hero-title {
  font-size: 60px;
  font-weight: 400;
  color: rgba(white,1);
  line-height: 0.92;
  font-family: $font2;
  @media(max-width: 1000px){
    font-size: 50px;
  }
  @media(max-width: 500px){
    font-size: 40px;
  }
}

.contact-hero-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(#c0bb87,1);
  line-height: 1.2;
  margin-bottom: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media(max-width: 1000px){
    font-size: 12px;
    margin: 0 30px 30px 30px;
  }
}


.contact-abheads {
  background: white;
  //min-height: 100vh;
  //padding: 20px 20px;
  position: relative;
  @media(max-width: 500px){
    display: none;
  }
}


.contact-abheads-title {
  display: block;
  position: absolute;
  top: -72vh;
  left: 45%;
  right: 0;
  z-index: 50;
  mix-blend-mode: multiply;
  width: 960px;
  pointer-events: none;

  html.mod-browser-ie &,
  html.mod-browser-edge & {
    color: rgba(#1a1a10,1);
    position: relative;
    z-index: 40;
  }

}

.contact-abheads-title-text {
  display: inline-block;
  font-size: 270px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  text-align: left;
  user-select: none;
  color: rgba(#c0bb87,1);
}

  .contact-hero-inner {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 200px;
    padding-bottom: 200px;
    position: relative;
    z-index: 15;
  }


    .contact-hero-title {
      font-family: $font2;
      font-size: 60px;
      font-weight: 400;
      line-height: 1.2;
      color: rgba(white,1);
      margin-bottom: 50px;

      @media(max-width: 1000px){
        font-size: 50px;
        margin: 0 30px;
      }
      @media(max-width: 500px){
        font-size: 40px;
      }
    }

    .contact-hero-sections-wrapper {

      &.mod-desktop {
        @media (max-width: 1000px) {
          margin: 0 30px;
        }
        @media (max-width: 500px) {
          display: none;
        }
      }

      &.mod-mobile {
        @media (min-width: 500px) {
          display: none;
        }
      }
    }

      .contact-hero-section {
        display: inline-block;
        width: 33.333%;
        margin-top: 70px;
        @media (max-width: 720px) {
          width: 50%;
          text-align: center;
        }

        @media (max-width: 500px) {
          width: 100%;
          padding: 50px 30px;
          border-bottom: 1px solid rgba(#b7b280, 0.2);
          margin: 0;
          &:last-of-type {
            border-bottom: 0;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              display: block;
              bottom: -40px;
              left: 25px;
              background: url(../img/raw/contact-arrow.svg);
              height: 75px;
              width: 13px;
              background-size: contain;
              background-repeat: no-repeat;
              z-index: 999;
              background-position: center;
            }
          }
        }
      }

      .contact-hero-section-title {
        font-family: $font2;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(#c0bb87,1);
      }

      .contact-hero-section-content {
        margin-top: 10px;
        //font-family: $font2;
        font-size: 12px;
        font-weight: 600;
        line-height: 2.2;
        color: rgba(255,255,255,.6);
        text-transform: uppercase;

      }


      .contact-hero-section-btn {
        margin-top: 10px;
        display: inline-block;
        //font-family: $font2;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.5;
        text-transform: uppercase;
        color: rgba(#fff,1);
        padding: 2px 5px;
        //border-bottom: 2px solid rgba(#fff,1);
      }




    .contact-hero-arrow {

    }





  .contact-map {
    min-height: 100vh;
    background-size: cover;
    background-position: 50%;
    position: relative;
    width: 70%;
    top: -5vh;
    margin-right: 150px;
    margin-left: auto;
    @media (max-width: 767px) {
      top: 0;
      width: 100%;
    }

    #map  {
      position: absolute;
      width: 100%;
      height: 100%;


      div {
        border-style: initial; // NEEDED FOR THE TOOLTIP ARROW POSITIONING
      }

      .gm-style-iw + div { // This hides the close button on the Tooltip
        display: none;
      }
    }

  }



    .contact-map-inner {
    }


      .contact-map-tooltip {
        //font-family: $font2;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(#25282a,1);
        background: white;
        padding: 20px 30px;
        border-radius: 1px;
        transition: 0.3s;
        &:hover {
          color: #c0bb87;
        }
        h2 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }

.contact-image {
  padding: 0 0  0 10%;
  @media (max-width: 500px) {
    display: none;
  }
  .contact-image-inner {

    img {
      max-width: 70%;
    }

  }
}
