.ventures {
  background: #fff;


  .venture-white-bg {
        background: rgba(255,255,255,.9);
    z-index: 9999;
    position: relative;
  }


  .ventures-hero {
    height: 70vh;
    @media (max-width: 1400px){
      height: 85vh;
    }
    @media (max-width: 500px){
      height: 100vh;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .ventures-hero-inner {
      text-align: center;
      img {
        max-width: 60%;
        @media (max-width: 500px) {
          max-width: 80%;
          margin-right: -13%;
        }
      }
    }
    &:after {
      content: '';
      background: url("../img/raw/mobile-hero-arrow.svg");
      @media (max-width: 500px) {
        height: 60px;
        width: 10px;
        display: block;
        z-index: 99;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .venture-line {
    position: absolute;
    width: 140px;
    height: 4px;
    background: #c0bb87;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    @media (max-width: 500px) {
      display: none;
    }
  }


  .ventures-content {
    padding: 60px 0;
    position: relative;

    .ventures-content-inner {
      display: flex;
      @media (max-width: 500px) {
        display: block;
      }
    }

    .ventures-content-title {
      font-size: 40px;
      letter-spacing: -1.3px;
      font-family: $font2;
      padding-right: 20px;
      flex: 1 0 60%;
      line-height: 1.3;
      @media (max-width: 1000px) {
       font-size: 48px;
      }
      @media (max-width: 500px) {
        font-size: 30px;
        line-height: 1.17;
        letter-spacing: -0.6px;

      }
    }
    .ventures-content-paragraph {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0.025em;
      margin-top: 15px;
      color: rgba(white, 0.7);
    }

    .ventures-icons {
      padding: 120px 45px;
      @media (max-width: 1000px) {
        padding: 120px 20px;
      }
      @media (max-width: 500px) {
        padding: 120px 0 0 0;
        flex-wrap: wrap;
      }
      display: flex;
      justify-content: space-between;
      .venture-icon {
        @media (max-width: 500px) {
          flex: 1 0 50%;
          padding-bottom: 60px;
        }
        text-align: center;
        font-size: 24px;
        line-height: 1.17;
        letter-spacing: 0;
        color: white;
        font-family: $font2;
        .venture-icon-text {
          padding-top: 25px;
        }
      }
    }
    .ventures-content-arrow {
      position: absolute;
      left: 42%;
      bottom: -220px;
      z-index:2;
      @media (max-width: 500px) {
        display: none;
      }
    }
  }




  .ventures-info {
    padding-top: 360px;
    padding-bottom: 160px;
    position: relative;
    @media (max-width: 500px) {
      padding: 120px 0 0 0;
    }

    .ventures-info-content {
      max-width: 540px;

      .ventures-info-title {
        font-size: 32px;
        line-height: 1.0;
        letter-spacing: 0.1px;
        margin-bottom: 20px;
        font-family: $font2;
      }
      .ventures-info-paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: 0.025em;
      }
    }

    .ventures-stats {
      padding: 60px 0;
      @media screen and (max-width: 500px) {
        padding: 60px 0 0 0;
      }
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        display: block;
      }
      .venture-stat{
        text-align: center;
        @media (max-width: 500px) {
          margin-bottom: 45px;
        }
        .venture-stat-number {
          font-size: 60px;
          font-family: $font2;
          line-height: 1.0;
          text-align: center;
          padding-bottom: 10px;
          color: #c0bb87;
        }
        .venture-stat-fact {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.17;
          letter-spacing: 1.7px;
          text-transform: uppercase;
        }
      }
    }
  }


  .ventures-current-ventures {

    padding: 180px 0;
    @media (max-width: 800px) {
      padding: 60px 0;
      padding-bottom: 0;
    }

    .ventures-current-ventures-inner {
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        align-items: center;
        flex-wrap: wrap;
      }

      .ventures-current-venture {
        position: relative;
        @media (max-width: 500px) {
          flex: 1 0 50%;
          //padding: 0 30px 30px 30px;
          padding: 15px;
        }
        img {
          max-width: 190px;
          max-height: 196px;
          @media (max-width: 900px) {
            max-width: 100%;
            padding: 5px;
          }
          @media (max-width: 500px) {
            display: block;
            max-width: 100%;
            max-height: none;
            height: auto;
          }
        }
        .venture-current-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          color: white;
          font-family: $font2;
          font-size: 48px;
          line-height: 1.0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          text-align: center;

          .venture-current-year {
          font-size: 9.6px;
          font-weight: 600;
          line-height: 1.17;
          letter-spacing: 1.4px;
          width: 100%;
          margin-top: 10px;
          font-family: $font1;
        }

          &:after {
            //content: '2018';
            //font-size: 9.6px;
            //font-weight: 600;
            //line-height: 1.17;
            //letter-spacing: 1.4px;
            //width: 100%;
            //margin-top: 10px;
            //font-family: $font1;
          }

        }
      }
    }
  }


  .ventures-abheads {
    padding-top: 120px;
    padding-bottom: 120px;
    //background: white;
    //min-height: 100vh;
    //padding: 20px 20px;
    position: relative;
    @media(max-width: 500px){
      display: none;
    }


      padding-top : 0;
      padding-bottom: 0;
      .ventures-abheads-title {
        top: -750px;
        left: 0;
      }
      .ventures-abheads-title-text {
        font-family: $font2;
        text-align: center;
        font-size: 260px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        @media(max-width: 1400px){
          font-size: 210px;
          font-weight: 200;
        }

        @media(max-width: 1000px){
          font-size: 120px;
        }

      }
  }


  .ventures-abheads-title {
    display: block;
    position: absolute;
    top: -85px;
    left: 10%;
    right: 0;
    z-index: 50;
    mix-blend-mode: multiply;


  }

  .ventures-abheads-title-text {
    display: inline-block;
    font-size: 300px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    text-align: left;
    user-select: none;
    color: rgba(#c0bb87,1);
    @media(max-width: 1000px){
      font-size: 180px;
    }
  }

  .ventures-cta {
    text-align: center;
    padding: 120px 0 60px 0;
    .ventures-cta-button {
      font-size: 16px;
      display: inline-block;
      padding: 20px 40px;
      font-weight: 600;
      transition: transform 0.3s, color 300ms ease-out;
      border-bottom: 1px solid;
      display: block;


        svg {

          transition: transform 300ms ease-out, opacity 300ms ease-out, color 300ms ease-out;

          opacity: 0;
          transform: translateX(-100%) translateY(-50%);

          height: 22px;
          padding-top: 7px;
          width: auto;

          display: inline-block;
          margin-left: 30px;
          position: absolute;
          top: 50%;

          g {
            transition: stroke 300ms ease-out;
            stroke: rgba(white,.9);
          }


          path {
            //fill: white;
          }
        }

        span {
          display: inline-block;
          transition: transform 0.3s, color 300ms ease-out;
        }

        &:hover {
          &:hover svg,
          &:focus svg {
            opacity: 1;
            transform: translateX(0%) translateY(-50%);
            g {
              stroke: rgba(#c0bb87,.95);
            }

          }

          &:hover span,
          &:focus span {
            color: rgba(#c0bb87,.95);
          }
        }


    }
  }

}



.ventures-container {
  max-width: 1100px;
  margin: auto;

  @media (max-width: 1100px) {
    padding: 20px;
  }
}

.ventures-dark {
  background: #25282a;
  color: white;
}

.ventures-light {
  //background: white;
}
