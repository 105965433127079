/*------------------------------------*\
  styles
\*------------------------------------*/



.abhead {
  margin: 0 auto;
  padding: 200px 0;
  @media (max-width: 700px) {
    padding: 20px 0;

  }

  margin-left: 150px;
  @media (max-width: 700px) {
    margin: 0;
  }
  &.mod-alt {
    margin-left: auto;
    margin-right: 150px;
    @media (max-width: 700px) {
      margin: 0;
    }
  }



}


  .abhead-inner {
    margin: 0 auto;
    width: 600px;
    @media (max-width: 700px) {
      width: 100%;
    }

  }



    .abhead-image {
      width: 600px;
      height: auto;
      @media (max-width: 700px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }


    .abhead-text {
      margin-left: auto;
      width: 500px;
      background: white;
      //margin-top: -60px;
      position: relative;
      z-index: 10;
      padding: 40px;
      @media (max-width: 700px) {
       width: 90%;
        margin-left: 0;
        margin-right: auto;
      }


      .abhead.mod-alt & {
        margin-left: 0;
        margin-right: auto;
      }


    }


      .abhead-title {
        font-size: 50px;
        font-weight: 400;
        color: rgba(#25282a,1);
        line-height: 1.2;
        font-family: $font2;
        @media (max-width: 800px) {
          line-height: 1.1;
          font-size: 48px;
        }

      }


      .abhead-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: rgba(#25282a,1);
        line-height: 1.2;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-top: 10px;
        @media (max-width: 800px) {
          line-height: 1.8
        }

      }


      .abhead-description {

        font-size: 15px;
        font-weight: 400;
        color: rgba(#25282a,.8);
        line-height: 1.6;
        letter-spacing: 0.025em;
        margin-top: 25px;
      }
